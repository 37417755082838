<template>
    <div class="brand product">
        <swiper id="banner" ref="banner" class="swiper banner" :options="bannerOPtions">
            <swiper-slide v-for="item in banners" :key="item.id">
                <auto-img :src="item.img" mul="29%" :path="item.url"></auto-img>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination" v-siwperPagination></div>
        </swiper>

        <div class="classifies">
            <Classify
                v-for="(item, index) in classifies"
                :key="index"
                :img="item.img"
                :icon="item.icon"
                :name="item.name"
                :choose="item.id === Number(chooseClassify)"
                :value="item.id"
                @changeChoose="changeClassify"
                :style="{ width: 100 / classifies.length + '%' }"
            ></Classify>
        </div>

        <el-container class="brand-main">
            <el-aside width="240px">
                <div class="filter-title">
                    <span>{{ $t('shai-xuan-tiao-jian') }}</span>
                    <el-button type="text" @click="refreash">{{ $t('zhong-zhi-shai-xuan-tiao-jian') }}</el-button>
                </div>

                <CountryTree v-model="chooseCountry" :continent.sync="continent"></CountryTree>

                <ProductBrandTree v-model="brand"></ProductBrandTree>

                <ProductCategoryTree
                    :chooseNode="chooseClassify"
                    v-model="chooseCategorys"
                    :defaultValue="defaultCategory"
                ></ProductCategoryTree>
                <ProductTagsTree v-model="chooseTags" :defaultValue="defaultTags"></ProductTagsTree>

                <ApplicationFieldTree
                    v-model="chooseApplicationFields"
                    :defaultValue="defaultApplicationField"
                ></ApplicationFieldTree>
            </el-aside>
            <el-container class="filter-show">
                <el-header height="56px" class="listTop">
                    <radio-group :list="sortList" v-model="sort"></radio-group>

                    <el-input
                        size="mini"
                        :placeholder="$t('zai-jie-guo-zhong-sou-suo')"
                        class="search"
                        v-model="searchKey"
                        clearable
                        @change="submitSearch"
                    >
                        <div slot="append" @click="submitSearch" class="iconfont">&#xe603;</div>
                    </el-input>

                    <span class="result" v-if="isChinese"
                        >{{ $t('dang-qian-jie-guo-gong') }}{{ totalElements }}{{ $t('ge-shang-pin') }}</span
                    >
                    <span class="result" v-else> Current: {{ totalElements }} result(s) </span>

                    <el-radio-group v-model="showType" class="show">
                        <el-radio-button :label="item.value" v-for="item in listTypes" :key="item.value">
                            <div class="btn-info">
                                <span class="iconfont" v-html="item.icon"></span>
                                <span>{{ $i18n.t(item.label) }}</span>
                            </div>
                        </el-radio-button>
                    </el-radio-group>
                </el-header>

                <el-main v-loading="loading">
                    <div class="mainList" ref="list">
                        <el-row :gutter="30" v-if="showType == 'gride'" type="flex">
                            <el-col v-for="item in list" :key="item.id">
                                <product-gride :info="item" :isNew="false" :search="search"></product-gride>
                            </el-col>
                        </el-row>
                        <template v-else>
                            <div class="row" v-for="item in list" :key="item.id">
                                <product-row :info="item" :isNew="false" :search="search" />
                            </div>
                        </template>
                    </div>
                    <empty-page v-if="empty"></empty-page>
                </el-main>
                <el-footer>
                    <el-pagination
                        @size-change="onSizeChange"
                        @current-change="onCurrentChange"
                        :current-page="page"
                        :page-sizes="[10, 20, 30, 40, 50]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalElements"
                    >
                    </el-pagination>
                </el-footer>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ProductGride from '../components/product/Gride';
import ProductRow from '../components/product/Row';
import Classify from '../components/product/Classify';
import { getBanner } from '../utils/CommonApi';
import pageableList from '../mixins/pageableList';
import ListFilter from '../components/ListFilter';
import { ProductSortType } from '../utils/AppState';
import CountryTree from '../components/tree/Countrys';
import ProductCategoryTree from '../components/tree/ProductCategory';
import ApplicationFieldTree from '../components/tree/ApplicationField';
import ProductTagsTree from '../components/tree/ProductTags';
import ProductBrandTree from '../components/tree/ProductBrand.vue';

export default {
    name: 'Product',
    mixins: [pageableList],
    data() {
        return {
            banners: [],
            bannerOPtions: {
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                speed: 600,
                parallax: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    bulletClass: 'banner-bullet',
                    bulletActiveClass: 'banner-bullet-active'
                }
            },
            productCategorys: [],
            productTags: [],
            applicationFields: [],
            chooseClassify: '',
            chooseCategorys: '',
            chooseTags: '',
            chooseApplicationFields: '',
            chooseCountry: '',
            start: true,
            defaultCategory: '',
            url: '/product/show',
            sortList: [...ProductSortType].map(item => {
                return {
                    key: item[0],
                    name: item[1]
                };
            }),
            brand: '',
            continent: ''
        };
    },
    computed: {
        ...mapState(['userInfo']),
        categoryProps() {
            return {
                children: 'children',
                label: this.$i18n.locale.indexOf('zh') !== -1 ? 'chName' : 'enName'
            };
        },
        classifies() {
            var list = [
                {
                    img: require('../assets/product/quanqiu_img_jixie.png'),
                    icon: require('../assets/product/quanqiu_icon_jixie.png')
                },
                {
                    img: require('../assets/product/quanqiu_img_jichuang.png'),
                    icon: require('../assets/product/quanqiu_icon_jichuang.png')
                },
                {
                    img: require('../assets/product/quanqiu_img_shebei.png'),
                    icon: require('../assets/product/quanqiu_icon_shebei.png')
                },
                {
                    img: require('../assets/product/quanqiu_img_celiang.png'),
                    icon: require('../assets/product/quanqiu_icon_celiang.png')
                },
                {
                    img: require('../assets/product/quanqiu_img_qita.png'),
                    icon: require('../assets/product/quanqiu_icon_qita.png')
                }
            ];

            var all = [...this.productCategorys];

            return all.map(item => {
                return {
                    ...list[Number(item.code || item.id) - 1],
                    name: this.$i18n.locale.indexOf('zh') !== -1 ? item.chName : item.enName,
                    id: item.id
                };
            });
        },
        showCategorys() {
            const list = [
                ...this.productCategorys.filter(item => {
                    return Number(item.id) === Number(this.chooseClassify);
                })
            ];
            return list.length > 0 ? list[0].children : [];
        },
        listQuery() {
            if (this.userInfo) {
                return {
                    categoryIds: this.chooseCategorys || this.chooseClassify,
                    tagIds: this.chooseTags,
                    applicationField: this.chooseApplicationFields,
                    productSort: this.sort,
                    countries: this.chooseCountry,
                    userId: this.userInfo ? this.userInfo.id : '',
                    continent: this.continent,
                    brand: this.brand
                };
            } else {
                return {
                    categoryIds: this.chooseCategorys || this.chooseClassify,
                    tagIds: this.chooseTags,
                    applicationField: this.chooseApplicationFields,
                    productSort: this.sort,
                    countries: this.chooseCountry,
                    continent: this.continent,
                    brand: this.brand
                };
            }
        },
        routeQuery() {
            return {
                chooseClassify: this.chooseClassify.toString()
            };
        },
        defaultTags() {
            return this.$route.query.tagIds || '';
        },
        defaultApplicationField() {
            return this.$route.query.applicationField || '';
        }
    },
    watch: {
        listQuery() {
            if (!this.start) {
                this.page = 1;
                this.getData();
            }
        },
        isChinese() {
            getBanner('PRODUCT', !this.isChinese).then(res => {
                this.banners = res;
                this.$nextTick(() => {
                    this.$refs.banner.$swiper.update();
                });
            });
        }
    },
    beforeRouteLeave(to, from, next) {
        if (to.name === 'productDetail') {
            this.$store.commit('updateKeepAlive', ['Product']);
        } else {
            this.$store.commit('updateKeepAlive', []);
        }
        setTimeout(() => {
            next();
        }, 100);
    },
    components: {
        Swiper,
        SwiperSlide,
        ProductGride,
        ProductRow,
        Classify,
        ListFilter,
        CountryTree,
        ProductCategoryTree,
        ApplicationFieldTree,
        ProductTagsTree,
        ProductBrandTree
    },
    mounted() {
        if (this.$route.query.categoryIds) {
            this.defaultCategory = this.$route.query.categoryIds;
        }
        this.loading = true;
        // this.getFiliterInfo();

        this.$http.get('/productCategory/tree').then(res => {
            this.productCategorys = res;
            this.chooseClassify = this.$route.query.chooseClassify || res[0].id;
            this.$nextTick(() => {
                this.start = false;
                this.getData();
            });
        });
        getBanner('PRODUCT', !this.isChinese).then(res => {
            this.banners = res;
            this.$nextTick(() => {
                this.$refs.banner.$swiper.update();
            });
        });
    },
    methods: {
        getFiliterInfo() {
            this.$http.get('/productCategory/allList').then(res => {
                this.productCategorys = res;
                this.chooseClassify = this.$route.query.chooseClassify || this.productCategorys[0].id;
            });
            this.$http.get('/productTag/allList').then(res => {
                this.productTags = res;

                //应用领域标签的42
                this.applicationFields = res
                    .filter(item => {
                        return item.id === 42;
                    })
                    .map(item => {
                        return item.children;
                    })
                    .flat();
                this.$nextTick(() => {
                    this.start = false;
                });
            });
            // Promise.allSettled([
            //     this.$http.get('/productCategory/allList'),
            //     this.$http.get('/productTag/allList')
            // ]).then(res => {
            //     res.forEach((item, index) => {
            //         if (item.status === 'fulfilled') {
            //             if (index === 0) {
            //                 //分类
            //                 this.productCategorys = item.value;
            //                 this.chooseClassify =
            //                     this.$route.query.chooseClassify ||
            //                     this.productCategorys[0].id;
            //             } else if (index === 1) {
            //                 //标签
            //                 this.productTags = item.value;

            //                 //应用领域标签的42
            //                 this.applicationFields = item.value
            //                     .filter(item => {
            //                         return item.id === 42;
            //                     })
            //                     .map(item => {
            //                         return item.children;
            //                     })
            //                     .flat();
            //                 this.$nextTick(() => {
            //                     this.start = false;
            //                 });
            //             }
            //         }
            //     });
            // });
        },
        changeClassify(id) {
            this.defaultCategory = '';
            this.chooseClassify = id;
        },
        refreash() {
            this.chooseCategorys = '';
            this.chooseTags = '';
            this.chooseApplicationFields = '';
            this.chooseCountry = '';
            this.brand = '';
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../styles/pagebleTable';
@import '../styles/variables';

.classifies {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;

    @include center-content(165px + 50px);

    .classify {
        flex-grow: 1;
        margin: 0 5px;
    }
}
</style>
